const chroniclesOfEmoryTate2011 = [
    {
        quote: '"Cowards die a thousand deaths, a brave man only one.  Stand against mediocrity.  <span class="red-text">Fight brainwashing</span>."',
        display: true,
        date: `Oct. 19, 2011`,
        image: "/images/emory-tate.png"
    },
    {
        quote: '"Andrew is now the Cobra, but he was born a Tiger.  Such a combination will never be beat!!!  <span class="red-text">Go team Cobra</span>"',
        display: true,
        date: `Nov 12, 2011`,
        image: "/images/emory-tate-young.png"
    },
    // {
    //     quote: '"Only adverts and sports replay on the orange channel link.  Are the lower fights not being shown?  Will they cut in for ANdrew?"',
    //     display: true,
    //     date: `Nov 12, 2011`,
    //     image: null
    // },
    // {
    //     quote: '"The orange TV link is a bust so far."',
    //     display: true,
    //     date: `Nov 12, 2011`,
    //     image: null
    // },
    {
        quote: '".<span class="red-text">Fighting  demands guts</span>.  Cobra fought, to win, for the contract.  Even a loss on his record was not worth his integrity as a superstar"',
        display: true,
        date: `Nov 12, 2011`,
        image: "/images/emory40.png"
    },
    {
        quote: '"And so we find time marches on.  Miracles happen  I am happy and I am sad that my son fought so hard.  All 7 rounds What a tough guy. <span class="red-text">Always</span>"',
        display: true,
        date: `Nov 14, 2011`,
        image: "/images/the-tate-pride.png"
    },
    {
        quote: '"Tristan is unleashed upon the world.... Love him or hate him.  <span class="red-text">he probly dont care</span>."',
        display: true,
        date: `Nov 15, 2011`,
        image: null
    },
    // {
    //     quote: '"Jason Bourne is the man.Yet he is fiction,one step above a cartoon.Want your ass kicked? Break my security space then?Move toward the light!"',
    //     display: true,
    //     date: `Dec 3, 2011`,
    //     image: "/images/emory59.png"
    // },
    // {
    //     quote: '"Jason Bourne really is the man... the lonely hero.  F$$K with him and you are ffkkkkin with the man.  Too bad it is fiction.  Tate"',
    //     display: true,
    //     date: `Dec 5, 2011`,
    //     image: null
    // },
    // {
    //     quote: '"Music does soothe the savage beast.  I listened to a harp and felt calm.  Now back to reality as I must go prowl among the sheep AGAIN!"',
    //     display: true,
    //     date: `Dec 6, 2011`,
    //     image: null
    // },
    {
        quote: '"Joining a group protest is not for my Tate personality.  I take it straight to the source of corruption.  And I stay alive!  <span class="red-text">Walk the walk</span>."',
        display: true,
        date: `Dec 6, 2011`,
        image: "/images/emory62.png"
    },
    {
        quote: '"The word homophobic needs to be rethought.  Nobody is afraid, actually.  The real word is homo-intolerant.  <span class="red-text"><br>I refuse to be called afraid<span>."',
        display: true,
        date: `Dec 7, 2011`,
        image: "/images/emory24.png"
    },
    {
        quote: '"Every wants to be a tough guy.Which of you have tasted your own blood.  When I was three,busted head.I <span class="red-text">DRANK</span> my blood.Daddy panicked. <span class="red-text">not me</span>"',
        display: true,
        date: `Dec 8, 2011`,
        image: "/images/emory56.png"
    },
    {
        quote: '"How has it become that I no longer can tell male thought from female thought.  Yes, I cry... <span class="red-text">but that does not mean I think like a woman</span>."',
        display: true,
        date: `Dec 8, 2011`,
        image: "/images/emory64.png"
    },
    {
        quote: '"It really is getting so much harder to identify a credible enemy anymore.  <span class="red-text">Could it be that all these people killed were not hostile at all?</span>"',
        display: true,
        date: `Dec 11, 2011`,
        image: "/images/emory57.png"
    },
    {
        quote: `"I appears to me that if I concentrate on happy ideas, no one can make me sad.  After all, I am the best that I've ever seen!! <span class="red-text">So are you</span>."`,
        display: true,
        date: `Dec 11, 2011`,
        image: "/images/emory37.png"
    },
    {
        quote: '"These people who know me, know by now they shoulda put me in charge.  I have never seen such a consistent record of failure in my life.  <span class="red-text">Sad</span>"',
        display: true,
        date: `Dec 11, 2011`,
        image: "/images/emory28.png"
    },
    // {
    //     quote: '"OCCUPY Twitter!!!  How u like me now, mo fo?"',
    //     display: true,
    //     date: `Dec 11, 2011`,
    //     image: null
    // },
    {
        quote: `"Vodka and guava juice at 3am, alone in a hotel room.negro gotta have a screw loose. I say "tighten up".don't lighten up, tighten up.  buzzed"`,
        display: true,
        date: `Dec 11, 2011`,
        image: "/images/emory20.png"
    },
    {
        quote: '"It does get a bit tricky when the only two bad ass men who could kick my ass happen to be my two sons.<span class="red-text">Everybody else better watch the fk out</span>"',
        display: true,
        date: `Dec 11, 2011`,
        image: "/images/the-tate-pride.png"
    },
    {
        quote: '"Without a baseline for truth, we (humans) are like savage apes, doing dirt in the dark, and smiling by day. <span class="red-text">Animals</span>.Still, noone is perfect."',
        display: true,
        date: `Dec 12, 2011`,
        image: null
    },
    {
        quote: '"I took a trip to Anhedonia! Do you know what I mean,I mean this place is hard to describe. Google maps places it between ennui and dejection"',
        display: true,
        date: `Dec 12, 2011`,
        image: null
    },
    {
        quote: '"Does being imprisoned in America mean free health care?Seems like a good trade-off.Go on a crime-spree then get meds in jail. Some sex too?!"',
        display: true,
        date: `Dec 12, 2011`,
        image: null
    },
    {
        quote: '"Chess is somewhat underrated.  A cerebral exercise, for certain,yet the war strategy aspect is often overlooked.  <span class="red-text">War is everywhere</span>. Believe"',
        display: true,
        date: `Dec 12, 2011`,
        image: "/images/emory15.png"
    },
    {
        quote: '"Caught between the longing for love and the struggle for the legal tender (The Pretender) song"',
        display: true,
        date: `Dec 12, 2011`,
        image: null
    },
    {
        quote: '"There is no Kyoto protocol.There is no way to control pollution.I see human pollution.Cant control it. <span class="red-text">these people think that they matter?</span>"',
        display: true,
        date: `Dec 12, 2011`,
        image: "/images/tateism.png"
    },
    {
        quote: '"I am honored to be (humble servant of the future) yet I am myself.  I am honored to be to your. dad.  no kidding.  <span class="red-text">Who could want more?</span>"',
        display: true,
        date: `Dec 12, 2011`,
        image: null
    },
    {
        quote: `"I've been a bit remiss by not flexing my vernacular. Now is time to <span class="red-text">hearken to the brazeness of modern men, hiding their true craven nature</span>"`,
        display: true,
        date: `Dec 13, 2011`,
        image: "/images/emory53.png"
    },
    {
        quote: `"Anhedonia is a little-known place, far beyond equanimity. Tate
        A strange place to visit, but I don't wann live there.  <span class="red-text">Gotta find a new drug</span>"`,
        display: true,
        date: `Dec 13, 2011`,
        image: null
    },
    {
        quote: '"Inaction is, of itself, <span class="red-text">an action</span>.  Sun Xu.  <span class="red-text">Idiots run the world and they are cowards too</span>.  Tate.  Dont fuk with me. Tateism"',
        display: true,
        date: `Dec 13, 2011`,
        image: "/images/emory2.png"
    },
    {
        quote: '"I knew this woul happen.no kidding, <span class="red-text">my actions forstall the inevitable but, in the end futile</span>.Think how many tweeters dont know futilility"',
        display: true,
        date: `Dec 13, 2011`,
        image: null
    },
    {
        quote: '"Liege Belgium in the news (wrong reasons).The train to Maastricht Nederland is quite cheap. Eurolines bus from London is adequate.Back door"',
        display: true,
        date: `Dec 14, 2011`,
        image: null
    },
    {
        quote: '"Everybody wants to be a tuf guy, but so few pay the price to get there.  These pretend clones in public make me uneasy.  <span class="red-text">I abhor cowards.</span>"',
        display: true,
        date: `Dec 15, 2011`,
        image: "/images/emory29.png"
    },
    {
        quote: `"The problem with cowards is that they can get you killed when <span class="red-text">THEY</span> panic!
        You might have to deal with the primary threat and <span class="red-text">K O the coward</span>"`,
        display: true,
        date: `Dec 15, 2011`,
        image: "/images/emory41.png"
    },
    {
        quote: '"The only way to explain the technical failure, moral and ethical failure and disregard for the truth??  <span class="red-text">Realize it was deliberate,  They win</span>"',
        display: true,
        date: `Dec 15, 2011`,
        image: null
    },
    {
        quote: '"Whereas it seems impossible for one man to be smarter that all the rest put together, <span class="red-text">One man can be right while everyone else is wrong. I</span>"',
        display: true,
        date: `Dec 15, 2011`,
        image: "/images/emory5.png"
    },
    {
        quote: `"All the inaction and failure I witnessed made angry, I asked my mom why wouldn't people do something.  <span class="red-text">You cain't do what you cain't think!</span>"`,
        display: true,
        date: `Dec 16, 2011`,
        image: null
    },
    {
        quote: '"Jealousy drives envy till they imitate, unable to emulate.  After practice, delusion follows and they try to erase the original.  <span class="red-text">Parasites</span>"',
        display: true,
        date: `Dec 16, 2011`,
        image: null
    },
    {
        quote: '"It appears to me that wisdom is replaced by knowledge. This fact undermines the elder statesman, and threatens all society.  <span class="red-text">Amateurs</span>."',
        display: true,
        date: `Dec 21, 2011`,
        image: "/images/emory40.png"
    },
    {
        quote: '"These fukkers on the train behind me started loud chat about computer stuff, incomprehensible!Can they be polite? <span class="red-text">can they stop my fist?</span>fail"',
        display: true,
        date: `Dec 21, 2011`,
        image: "/images/emory20.png"
    },
    {
        quote: `"Old people are somehow "funny" All dead mf's who died young must be absolutely hilarious. (group laughter) <span class="red-text">Is the very meaning of life lost?</span>"`,
        display: true,
        date: `Dec 21, 2011`,
        image: null
    },
    {
        quote: '"Becoming old means my enemies had to fail... had to hide, had to return to the roots of cowardice.<span class="red-text">To face me is to lose</span>, Tateism."',
        display: true,
        date: `Dec 21, 2011`,
        image: "/images/emory60.png"
    },
    {
        quote: '"Imagine deer, antelope, allowed the adolescent males to break in all the females.Unproven sperm through the bloodline. <span class="red-text">The alpha dies</span>. Shit."',
        display: true,
        date: `Dec 21, 2011`,
        image: null
    },
    {
        quote: '"No need for debate about Jesus, here at Christmas time. Find the ways that you are crucified.  <span class="red-text">Seek your enemies, and stop the torture</span>. Tate"',
        display: true,
        date: `Dec 23, 2011`,
        image: null
    },
    {
        quote: '"The envious cowards of my generation have shown me many things. And they talk, talk. I have seen a lot, but I HAVE HEARD IT ALL!!  <span class="red-text">Silence!</span>"',
        display: true,
        date: `Dec 24, 2011`,
        image: null
    },
    {
        quote: `"Today is a good day to evaluate your belief system. Looks like pure christianity is fading away, the priests' true perversions revealed.<span class="red-text">Damn</span>"`,
        display: true,
        date: `Dec 25, 2011`,
        image: "/images/emory33.png"
    },
    {
        quote: '"He who hesitates is lost I once hesitated to protect a system. <span class="red-text">The system is broken</span> What to do now? Cowards,traitors,amateurs.Epic fail. Sad"',
        display: true,
        date: `Dec 25, 2011`,
        image: "/images/emory28.png"
    },
    {
        quote: '"To achieve brilliance is a wonderful thing.<span class="red-text">To act brilliantly is rare</span>.Take action and where you fit in on the brilliance scale?"',
        display: true,
        date: `Dec 26, 2011`,
        image: null
    },
    {
        quote: '"God only knows.  Should I go to Vegas (big chess tournament) or should I wait in fear for another year.  Dammit man....answer me.  Tweeters?"',
        display: true,
        date: `Dec 26, 2011`,
        image: null
    },
    {
        quote: '"The point is this.  Can you take the heat?  Fuking then shut up.. sit down.  take whatever comes.  Become a clone.<span class="red-text">Or choose individualism.</span>"',
        display: true,
        date: `Dec 26, 2011`,
        image: null
    },
    {
        quote: '"It is not just that I am better than you.  <span class="red-text">I am simply superior</span>.  Proven. No question.  You wanna ask questions?  <span class="red-text">Ask my world-class boys</span>."',
        display: true,
        date: `Dec 30, 2011`,
        image: null
    },
    {
        quote: '"When you no longer care about your family, the psycholgy breaks deep inside.From that point all love becomes a mathematical calculation. <span class="red-text">Bad</span>"',
        display: true,
        date: `Dec 31, 2011`,
        image: null
    },
    {
        quote: '"Brock Lesnar had too much muscle to punch effectively.Bulk, not quick-twitch fiber. When this happens to a man he is called muscle-bound."',
        display: true,
        date: `Dec 31, 2011`,
        image: null
    },
    {
        quote: '"Personally I have spent much of my life staring-down muscle-bound bullies.  Somehow they know, deep inside, to touch a man like me is <span class="red-text">DEATH</span>"',
        display: true,
        date: `Dec 31, 2011`,
        image: null
    },
    {
        quote: '"How is it that  Nov 12 election 4 prez dominates the news daily?Do these damn narcissists even believe that they will survive till Nov?News?"',
        display: true,
        date: `Dec 31, 2011`,
        image: null
    },
    {
        quote: '"they are teaching gay issues to 7-yr old kids. <span class="red-text">BY LAW</span>.  A pure homosexual can not reproduce, <span class="red-text">so they need your children for new partners</span>. OK"',
        display: true,
        date: `Dec 31, 2011`,
        image: null
    },
    {
        quote: '"Even as New Year approaches West Coast, I seek the correct feeling.  The "E" word.Not ecstasy per se, rather Equanimity.<span class="red-text">A core professional</span>"',
        display: true,
        date: `Dec 31, 2011`,
        image: null
    },
    {
        quote: `"2hrs15 min till New Year's yet I am keenly aware that I've not made it yet.No time for mental laziness,<span class="red-text">enemies are so profuse</span>."`,
        display: true,
        date: `9:45pm Dec 31, 2011`,
        image: null
    },
    {
        quote: `"He who hesitates is lost I once hesitated to protect a system. <span class="red-text">The system is broken</span> What to do now? Cowards,traitors,amateurs.Epic fail.Sad"`,
        display: true,
        date: `Jan 1, 2012`,
        image: null
    },
    {
        quote: `"Old people are somehow "funny" All dead mf's who died young must be absolutely hilarious.(group laughter) <span class="red-text">Is the very meaning of life lost?</span>"`,
        display: true,
        date: `Jan 1, 2012`,
        image: null
    },
    {
        quote: `"An old man told me(I was 20 yrs) Never scare a coward.I told him"I aint scary".YES YOU ARE <span class="red-text">Now a generation of cowards are afraid of me</span>.OOPS"`,
        display: true,
        date: `Jan 1, 2012`,
        image: null
    },
    {
        quote: `"I watch the most capable athletes in the world,but feel no envy.When it was my turn(as a man) I conquered. <span class="red-text">Find achievement and be capable.</span>"`,
        display: true,
        date: `Jan 2, 2012`,
        image: null
    },
    {
        quote: `"It appears to me that many Chinese live without that common feeling of human <span class="red-text">ENVY</span>. They seem to be so driven.They are just being themselves."`,
        display: true,
        date: `Jan 8, 2012`,
        image: null
    },
    {
        quote: `"A highly-educated (formal education) PHD quips (to me) maybe I could have been you and you could have been the phd.He doesn't get it.No tks."`,
        display: true,
        date: `Jan 8, 2012`,
        image: null
    },
    {
        quote: `"WORDGAME.  BRIGADOON.Why would groups of morons assume that I seek and live in brigadoon. Are they not the ones divorced from reality? <span class="red-text">Hell</span>"`,
        display: true,
        date: `Jan 8, 2012`,
        image: null
    },
    {
        quote: `"Their children struggle with the chasm between the real and the farcical.  <span class="red-text">Psychologies break</span> and harsh drugs try to "fix" the problem. Hell"`,
        display: true,
        date: `Jan 8, 2012`,
        image: null
    },
    {
        quote: `"AND SO,young man with big hair smiles a death-smile,athletic. White dude flips me the middle finger.I'm in my 50's.Can you believe it?<span class="red-text">weak</span>."`,
        display: true,
        date: `Jan 9, 2012`,
        image: null
    },
    {
        quote: `"BIG AD CAMPAIGN ! To protect you from MAYHEM, like ALLSTATE.  Is that not a dangling modifier?  What?  Allstate become the mayhem.  <span class="red-text">Idiots</span>."`,
        display: true,
        date: `Jan 9, 2012`,
        image: null
    },
    {
        quote: `"We must protect you from terror like the American government. Must be, "We can protect you like the govt DOES.  <span class="red-text">Perhaps the semantics fail</span>."`,
        display: true,
        date: `Jan 9, 2012`,
        image: null
    },
    {
        quote: `"With children like mine, Janine Tristan and Andrew, it is easy to be proud. Then I remember that I am the Man.  Not narcissistic.  <span class="red-text">Fact</span>."`,
        display: true,
        date: `Jan 13, 2012`,
        image: null
    },
    {
        quote: `"I must admit, the clowns who perpetrate their "games" on free society are no longer funny."funny?" .<span class="red-text">Not out here on the sharp end</span>.  Shut up"`,
        display: true,
        date: `Jan 13, 2012`,
        image: null
    },
    {
        quote: `"Physical force is underrated,S.Do something more effective than "walking off" and pretending.He must learn his place in life"`,
        display: true,
        date: `Jan 15, 2012`,
        image: null
    },
    {
        quote: `"Confusing knowledge with wisdom is a dangerous trend. Life may seem like a game,but in fact is deadly serious. <span class="red-text">Wisdom breeds action</span>, Tateism"`,
        display: true,
        date: `Jan 16, 2012`,
        image: "/images/emory5.png"
    },
    {
        quote: `"He who shuns the dust of the arena, shall not sit in the shade of the olive tree.  Fight! or <span class="red-text">live in the shadow of one's own cowardice</span>. Fail"`,
        display: true,
        date: `Jan 16, 2012`,
        image: null
    },
    {
        quote: `"Caught between the longing for love and the struggle for the legal tender.
        Was this a doub entendre all along?  "The Pretender" Classic song"`,
        display: true,
        date: `Jan 16, 2012`,
        image: null
    },
    {
        quote: `"I now think of our men at war and we veterans and wonder.  <span class="red-text">Did we sacrifice to protect a greedy super-elite?</span> That would be a life-changer."`,
        display: true,
        date: `Jan 20, 2012`,
        image: null
    },
    {
        quote: `"Again I worry, if gays can not have children,all they need is <span class="red-text">YOUR</span> children to continue their sex lives.<span class="red-text">How could this logic be flawed?</span> Tate"`,
        display: true,
        date: `Jan 22, 2012`,
        image: null
    },
    {
        quote: `"Turning a "blind eye" does not make you cool. A non-reaction isnt "smooth"  <span class="red-text">It makes you weak,unaware and a walking target for hostile acts</span>"`,
        display: true,
        date: `Jan 23, 2012`,
        image: null
    },
    {
        quote: `"When you are sharing important matters with a friend,and they make inane jokes while you try to share.They are your enemy. <span class="red-text">Poisoning the air</span>"`,
        display: true,
        date: `Jan 25, 2012`,
        image: null
    },
    {
        quote: `"These politicians are funny.Curiously so. We must stop selling our factories and jobs <span class="red-text">NOW</span>. Bring back our stuff.Stop bleeding, then fix it."`,
        display: true,
        date: `Jan 25, 2012`,
        image: null
    },
    {
        quote: `"Never slowin down and I shoulda started runnin' <span class="red-text">a long time ago....</span>"`,
        display: true,
        date: `Jan 25, 2012`,
        image: null
    },
    {
        quote: `"The first human reaction to FEAR, is <span class="red-text">SILENCE</span>. Learned helplessness. Difficult to induce, Impossible to maintain. Tateism. I TWITTER. chirp?"`,
        display: true,
        date: `Feb 7, 2012`,
        image: null
    },
    {
        quote: `"How is it nobody ever talks about the future act of having a family.  Sure the world is rough, but somebody brought YOU here.  <span class="red-text">Sell Fish!!</span>"`,
        display: true,
        date: `Feb 13, 2012`,
        image: null
    },
    {
        quote: `"I visited Anhedonia! Can you find its location?? Google maps places it midway between Ennui and Dejection.
        WTF you say!? Speak English pls"`,
        display: true,
        date: `Feb 13, 2012`,
        image: null
    },
    {
        quote: `"Rape in the military terrible. But when a fem soldier foreplays on some dude's face and leaves a dude with blue-balls, <span class="red-text">did she rape him?</span> yes"`,
        display: true,
        date: `Feb 14, 2012`,
        image: null
    },
    {
        quote: `"When your name is Tristan Tate, the people love to hate. They can't duplicate, nor even emulate. It's sad to imitate. <span class="red-text">Silence all debate</span>."`,
        display: true,
        date: `Feb 27, 2012`,
        image: null
    },
    {
        quote: `"Tristan is champ in the ring 
        On Shipwrecked he was Fire King
        He has a REAL skill
        You'll not break his will
        A kick and the bell will go DING"`,
        display: true,
        date: `Feb 27, 2012`,
        image: null
    },
    {
        quote: `"If one thing I have learned, cowards hunt in packs.  Some cowards join gangs or even, , police forces or secret centers of power.  <span class="red-text">Beware</span>."`,
        display: true,
        date: `Mar 4, 2012`,
        image: null
    },
    {
        quote: `"Some moron recommends that I "pay attention". For each thing you see there is something else you miss.  I should have told him <span class="red-text">STFU</span>. #Child"`,
        display: true,
        date: `Mar 5, 2012`,
        image: null
    },
    {
        quote: `"Lonely housewife tells handsome handyman to paint indoor walls as well as outside.  After that it was inner and outer, inner and outer."`,
        display: true,
        date: `Mar 6, 2012`,
        image: null
    },
    {
        quote: `"Anhedonia. A place without pleasure, beyond the grasp of pain.Only real events catch the attention.Vision is black + white.  
        <span class="red-text">No cure needed</span>"`,
        display: true,
        date: `Mar 7, 2012`,
        image: null
    },
    {
        quote: `"The Tate problem, conundrum (if you will) is not whether they accept me in their world.  Question is; <span class="red-text">Do I allow them entrance into MINE</span>."`,
        display: true,
        date: `Mar 7, 2012`,
        image: null
    },
    {
        quote: `"One must admit that the racial hatreds have gone "underground".  They don't shout NIGGER anymore, <span class="red-text">they just quietly discriminate and ignore</span>."`,
        display: true,
        date: `Mar 9, 2012`,
        image: null
    },
    {
        quote: `"The ability to read minds develops after many decades. Still, it is becoming useless now as empty-headed "cool" people are tuned out. #lost"`,
        display: true,
        date: `Mar 9, 2012`,
        image: null
    },
    {
        quote: `"My mind-reading skills are rendered neutral, as the streets fill with empty-headed youth, no goals, and very little self-respect. #PIERCEME"`,
        display: true,
        date: `Mar 11, 2012`,
        image: null
    },
    {
        quote: `"Living in a state of paranoia and looking is dangerous to one's physical and mental health and also results in poor security. 
        <span class="red-text">Rodent-like</span>."`,
        display: true,
        date: `Mar 15, 2012`,
        image: null
    },
    {
        quote: `"Many animals are immune to their own poison. The toxic HUMAN realizes that his  toxin kills even himself, <span class="red-text">toxifies his kids, all</span>. #epicfail"`,
        display: true,
        date: `Mar 20, 2012`,
        image: null
    },
    {
        quote: `"Conformity is the touchstone of failed genius.  Show conformist, <span class="red-text">I will show you average</span>. BTW tattoo and piercing is another way to conform."`,
        display: true,
        date: `Mar 20, 2012`,
        image: null
    },
    {
        quote: `"Problem with small-minded people is that one can always tell what they are thinking. <span class="red-text">It is rarely even original, good, or safe</span>. #idiotbuddy"`,
        display: true,
        date: `Mar 21, 2012`,
        image: null
    },
    {
        quote: `"I allow manipulation to find out where my enemy wants me to go. Then I <span class="red-text">use my mind to break the trap and punish the perpetrators</span>. #alwayswin"`,
        display: true,
        date: `Mar 21, 2012`,
        image: null
    },
    {
        quote: `"No big fan of group causes I admit that killing a young black man in cold blood has me angry.  <span class="red-text">These mo fo's would kill us all</span>, if they cud"`,
        display: true,
        date: `Mar 25, 2012`,
        image: null
    },
    {
        quote: `"Few people know the terror of being hunted down by armed assassins. Fewer survive. Ask me. TRAYVON's last scream of "HELP" <span class="red-text">chills my soul</span>."`,
        display: true,
        date: `Mar 25, 2012`,
        image: null
    },
    {
        quote: `"The soft underbelly of the "New World Order" is exposed-"CONNECTED" people steal all wealth. Idiots march in lockstep. <span class="red-text">Mimic then DIE</span>#zombie"`,
        display: true,
        date: `Mar 26, 2012`,
        image: null
    },
    {
        quote: `"Action remains when we run out of excuses.Contentment is left when we exhaust our lust. Ashes remain from fire, <span class="red-text">we all return to the dust</span>."`,
        display: true,
        date: `Mar 27, 2012`,
        image: null
    },
    {
        quote: `"Now the new form of evil scientist has fucked up the behavioural matrix.Parent to child, woman toward man, <span class="red-text">weak man vs alpha male</span>. #epicfail"`,
        display: true,
        date: `Mar 28, 2012`,
        image: null
    },
    {
        quote: `"What's the difference between seeing and comprehending?'tween moving, and taking action.'tween focus and distraction, <span class="red-text">love and satisfaction?</span>"`,
        display: true,
        date: `Mar 28, 2012`,
        image: null
    },
    {
        quote: `"difference between seeing and comprehending?' moving, and taking action.'tween focus and distraction, love and satisfaction?
        @Elena_Petreska"`,
        display: true,
        date: `Mar 28, 2012`,
        image: null
    },
    {
        quote: `"The former taste of love, of feelings shared, of feeling lost, A faded memory now, <span class="red-text">our passion cold beneath the frost</span>.  #exlover"`,
        display: true,
        date: `Mar 29, 2012`,
        image: null
    },
    {
        quote: `"The truth is underestimated, often is manipulated, oft-times unanticipated,rarely unappreciated,never to be mitigated,<span class="red-text">often to be celebrated</span>"`,
        display: true,
        date: `Mar 29, 2012`,
        image: null
    },
    {
        quote: `"Enemies of Alpha males are weak.<span class="red-text">They hunt in packs</span>.They invent motives to harm. Mostly, they pretend to be the alpha's friends, <span class="red-text">to get close</span>"`,
        display: true,
        date: `Mar 30, 2012`,
        image: null
    },
    {
        quote: `"Honestly. There is a problem with the hoodie. Just like the burkha or the assassin's balaclava. It is not a racial thing, <span class="red-text">it is a disguise</span>."`,
        display: true,
        date: `Mar 30, 2012`,
        image: null
    },
    {
        quote: `"Intelligence wins against cunning! Not that some fk can't bash your head with a rock  Even if it kills you he can't say <span class="red-text">"I am smarter!"</span>#fail"`,
        display: true,
        date: `Apr 11, 2012`,
        image: null
    },
    {
        quote: `"Can cowards tell you to "have courage" Can athiests say "God help me" Can narcissists say "I love you" <span class="red-text">Can dictators say "Freedom"</span> #puzzling"`,
        display: true,
        date: `Apr 12, 2012`,
        image: null
    },
    {
        quote: `"Zimmerman has had enough time to detox from the crystal meth and alcohol from the night of the shooting. A fresh haircut kills the hair test"`,
        display: true,
        date: `Apr 12, 2012`,
        image: null
    },
    {
        quote: `"Get the fk out of Afghanistan.  This is a  killing ground, and is built for the task.If the bad guy dont getchya you still die.
        #commonsense"`,
        display: true,
        date: `Apr 17, 2012`,
        image: null
    },
    {
        quote: `"The real question about legal drugs and prostitution is this.  Will those in power ever take the bootheel off the little man's neck. #prison"`,
        display: true,
        date: `Apr 17, 2012`,
        image: null
    },
    {
        quote: `"Being real means NOT being a reflection of pop culture.  <span class="red-text">How can you be an individual when your very thoughts are cloned</span>. Be urself. #fake"`,
        display: true,
        date: `Apr 19, 2012`,
        image: null
    },
    {
        quote: `"Game? The Russians are playing Chess, the Americans - checkers/drafts The Chinese play Go, and the E U - tic-tac-dough. #wtf
        #winthefuture"`,
        display: true,
        date: `Apr 24, 2012`,
        image: null
    },
    {
        quote: `"After America's double-jump in checkers, they were checkmated by Russia, who was surrounded by Go stones from China. The EU lost money #fail"`,
        display: true,
        date: `Apr 25, 2012`,
        image: null
    },
    {
        quote: `"Does no one realize that Killing Bin Laden was the coward's way?  Take him alive, and trace the Pakistani networks. <span class="red-text">Brave</span>. #idiots #oldman"`,
        display: true,
        date: `Apr 27, 2012`,
        image: null
    },
    {
        quote: `"One wonders what they might sell off next?  We sold our manufacturing base and we even sold out some special people. <span class="red-text">It is a National shame</span>"`,
        display: true,
        date: `Apr 28, 2012`,
        image: null
    },
    {
        quote: `"Watching cobraacademy of martial arts http://livestre.am/D5Iu via 
        @livestream"`,
        display: true,
        date: `Apr 29, 2012`,
        image: null
    },
    {
        quote: `"livestream.com/cobraacademy
        Watch TRISTAN Tate fight.  Live now.  Coming soon.... European TITLE Belt"`,
        display: true,
        date: `Apr 29, 2012`,
        image: null
    },
    {
        quote: `"livestream.com/cobraacademy  Watch my son fight.  Check his older brother (my namesake) King Cobra Tate. World Champ now.  They idolize you."`,
        display: true,
        date: `Apr 29, 2012`,
        image: null
    },
    {
        quote: `"TRISTAN HAS NOT FOUGHT YET... I had bad info.  TRISTAN must WIN.  Tweeters, tune in NOW NOW http://livestream.com/cobraacademy
        The truth shall be revealed"`,
        display: true,
        date: `Apr 29, 2012`,
        image: null
    },
    {
        quote: `"Beyond betrayal and treason - the fact that They are proud of it.Billions tax-free. <span class="red-text">Heroes homeless</span>. Now U S kids say "epic fail". #sadfools"`,
        display: true,
        date: `May 2, 2012`,
        image: null
    },
    {
        quote: `"The comic book killed Superman, Obama stopped NASA space shuttles.Kids nowadays find "epic fail" funny <span class="red-text">and kill themselves on f/b</span>. #mindfuck"`,
        display: true,
        date: `May 2, 2012`,
        image: null
    },
    {
        quote: `"I uncover mask of the assassins'.They walk around telling inane jokes,insinuating harmful action. The tone is bored. Ennui.
        #bland #disfunct"`,
        display: true,
        date: `Jun 5, 2012`,
        image: null
    },
    {
        quote: `"Money on the line, fight judges cheat.  Pacquio judges - one million cash while Kotrijk judges had to split 1000 euros to cheat Andrew.#fkme"`,
        display: true,
        date: `Jun 11, 2012`,
        image: null
    },
    {
        quote: `"FAILURE tastes like a thick,bitter liquid  hard to swallow. <span class="red-text">With an aftertaste of unmentionable rankness</span>. This is what I am told.#neverfail"`,
        display: true,
        date: `Jun 18, 2012`,
        image: null
    },
    {
        quote: `"Why do inferior males always have something smart to say, when with a group or gang.  <span class="red-text">Alone they are as silent as frightened birds</span>. #coward"`,
        display: true,
        date: `Jun 22, 2012`,
        image: null
    },
    {
        quote: `"When you run out of cliche and pop-culture knowledge your real thoughts seem so useless Right?  <span class="red-text">Look at what they have done to you</span>. #Improve"`,
        display: true,
        date: `Jul 1, 2012`,
        image: null
    },
    {
        quote: `"Work Consume Die. Good Luck Life goes on Take care Hang in there Nothing lasts forever Does not kill makes you stronger.  <span class="red-text">Forgive and forget</span>"`,
        display: true,
        date: `Jul 1, 2012`,
        image: null
    },
    {
        quote: `"We "congratulate" Ander Cooper when he OUTS himself, or Frankie Ocean. Explain the ACHIEVEMENT of being gay. <span class="red-text">I made children</span>. #Nobodycares."`,
        display: true,
        date: `Jul 4, 2012`,
        image: null
    },
    {
        quote: `"The truth is if I could clone myself. I would not.  The better versions of me already walk the planet. <span class="red-text">Love was my link to the future</span>. kids#"`,
        display: true,
        date: `Jul 16, 2012`,
        image: null
    },
    {
        quote: `"As the problem reveals itself I know that people realize that they are their own worst enemy.  <span class="red-text">As such they turn their hate outward</span>. #fail"`,
        display: true,
        date: `Jul 29, 2012`,
        image: null
    },
    {
        quote: `"Perhaps "we" should arm the all non-white countries. Automatic weapons.  Shooting brings peace. "They" reproduce too much anyway. #genocide"`,
        display: true,
        date: `Jul 29, 2012`,
        image: null
    },
    {
        quote: `"A combination of my sheer indefagitability, and my unequalled perspicacity makes me a feared adversary, <span class="red-text">regardless of field of endeavour</span>"`,
        display: true,
        date: `Aug 24, 2012`,
        image: null
    },
    {
        quote: `"Anybody who thinks they have children in my kid's league, tell me! I know we all truly love our kids. Still, <span class="red-text">I am certain mine are "perfect"</span>"`,
        display: true,
        date: `Sep 4, 2012`,
        image: null
    },
    {
        quote: `"A dude circulates a list that unsucessful people watch TV but sucessful ones (like him?) dont. In response watch eastenders omnibus.2X"`,
        display: true,
        date: `Sep 7, 2012`,
        image: null
    },
    {
        quote: `"All these people talking forgiveness.It is appropriate to get on your knees while begging.  <span class="red-text">Men should never forget wrongs</span> #perservere"`,
        display: true,
        date: `Sep 10, 2012`,
        image: null
    },
    {
        quote: `"Ochocino not NFL? Chad Johnson?Got arrested beating a woman? Now, like or not, we got a real brotha.#bleed  they will have ur money!! Tyson"`,
        display: true,
        date: `Sep 13, 2012`,
        image: null
    },
    {
        quote: `"Life only has 3 categories.  Cause and effect.  Purpose and intent. Action and reaction.  <span class="red-text">When you master all three realms you begin Tateism</span>"`,
        display: true,
        date: `Sep 22, 2012`,
        image: null
    },
    {
        quote: `"Obamacare question.. when some family sends a check to cover the mandate, and that check bounces. bank fees, no food, no gas.. what next?"`,
        display: true,
        date: `Oct 5, 2012`,
        image: null
    },
    {
        quote: `"When the final mind-game is played, and death awaits, you might wonder.<span class="red-text">If I did not mind did it matter</span>.  Your moves, sand on the beach.#fear"`,
        display: true,
        date: `Oct 7, 2012`,
        image: null
    },
    {
        quote: `"My 83 yr  mom, pulls a stuck mower/tractor out of mud at pond..No motor help. Her reply "If I stop doin' it I won't be able to do it." <span class="red-text">ninja</span>"`,
        display: true,
        date: `Nov 12, 2012`,
        image: null
    },
    {
        quote: `"People say "I am a vegetarian".  No such thing. Human species.  Better stated one  practices vegetarianism.  <span class="red-text">It is religion, not a reality</span>."`,
        display: true,
        date: `Feb 8, 2013`,
        image: null
    },
    {
        quote: `"The  average human in the postmodern world works to have leisure  time. Work to sit and relax. <span class="red-text">We must break the work,consume, die  cycle</span>"`,
        display: true,
        date: `Feb 8, 2013`,
        image: null
    },
    {
        quote: `"It appears the insanity of the ruling elite is exposed worldwide now.  <span class="red-text">They will kill you</span>.  Keep your mouths shut, behave as sheep. #sadlife"`,
        display: true,
        date: `Feb 28, 2013`,
        image: null
    },
    {
        quote: `"Look in a mirror and ask yourself, <span class="red-text">who is leading me.Why do I act this way?</span>If you believe the people on Tele =leaders, remain a puppet!#fool"`,
        display: true,
        date: `Mar 2, 2013`,
        image: null
    },
    {
        quote: `"It is curious how the majority of old people seem beaten down.Not in my family. Even a stroke leaves no reminders.  <span class="red-text">Mom is original Ninja</span>."`,
        display: true,
        date: `Mar 3, 2013`,
        image: null
    },
    {
        quote: `"The psychological effects of mind-fuck are not understood.<span class="red-text">We learned nothing from the Nazi failure</span>.Get  into lock-step.Lose ur mind. #puppet"`,
        display: true,
        date: `Mar 3, 2013`,
        image: null
    },
    {
        quote: `"A man does not acquire a full skill-set to be told,"Take Care" be careful" by amateurs.  Raw
        action solves everything. <span class="red-text">Caution breeds fear</span>."`,
        display: true,
        date: `Mar 6, 2013`,
        image: null
    },
    {
        quote: `"Some dude is called "cannibal cop" convicted by a jury of panicked Americans to life in prison for internet chat.<span class="red-text">He never did a thing</span>. #fail"`,
        display: true,
        date: `Mar 13, 2013`,
        image: null
    },
    {
        quote: `""Who chains us.and who holds the key that can  set us free? It's you. You have all the weapons you need. Now fight!" (MOVIE SUCKER PUNCH)"`,
        display: true,
        date: `Mar 15, 2013`,
        image: null
    },
    {
        quote: `"Gays (esp men) <span class="red-text">can't reproduce so they need YOUR children</span>.Men will marry men to get tax break/govt benefits.Gay divorce will clog courts.#NO"`,
        display: true,
        date: `Mar 28, 2013`,
        image: null
    },
    {
        quote: `"The problem with modern dudes is that their main sex organ is the tongue. Favourite position,head between thighs.<span class="red-text">I fail and now have 3 kids</span>."`,
        display: true,
        date: `Apr 15, 2013`,
        image: null
    },
    {
        quote: `"How can We still fund military biofuel? Result?We burn food and greenage in order to make fuel to wage war! <span class="red-text">Fuck a starving baby</span> .#killall"`,
        display: true,
        date: `Apr 15, 2013`,
        image: null
    },
    {
        quote: `"Lack of leadership can kill sure as a bullet. Just ask me (alive) or Ambassador Stevens (RIP).<span class="red-text">Cries for assistance unanswered</span>, leaders fail."`,
        display: true,
        date: `Sep 9, 2013`,
        image: null
    },
    {
        quote: `"Zimmer's whole story was a lie on TV and they played it and entered it as court record.  ZIMM was not sworn in!!!!!!!!!!  Television.  WTF"`,
        display: true,
        date: `Sep 10, 2013`,
        image: null
    },
    {
        quote: `"Perjury is a zero-sum game. Both can't win. He had the gun or she lied to  911. <span class="red-text">One must go to jail</span>. Zim in jail or she violates plea deal."`,
        display: true,
        date: `Sep 10, 2013`,
        image: null
    },
    
]

export default chroniclesOfEmoryTate2011